var __jsx = React.createElement;
import React from 'react';
export default {
  ACCOUNT_DETAILS_UPDATED: 'Account details updated successfully.',
  CONNECTION_ERROR: 'Could not connect to the server. Check your internet connection and refresh the page.',
  DELETING_PROJECT: 'Deleting project...',
  DELETING_SAMPLE: 'Deleting sample...',
  ERROR_CREATING_SAMPLE: 'We couldn\'t create your sample. Please try uploading it again',
  ERROR_CREATING_PROJECT: 'We couldn\'t create your project.',
  ERROR_LOADING_PROJECT: 'We couldn\'t load your project.',
  EMPTY_CLUSTER_NOT_CREATED: 'Cannot create a cluster that would be empty.',
  ERROR_DELETING_PROJECT: 'We couldn\'t delete your project.',
  ERROR_DELETING_SAMPLES: 'We couldn\'t delete samples from your project.',
  ERROR_DOWNLOADING_DATA: 'Error downloading data.',
  ERROR_FETCHING_CELL_SETS: 'We couldn\'t get the list of cell sets.',
  ERROR_FETCHING_EXPERIMENTS: 'We couldn\'t get the list of experiments.',
  ERROR_FETCHING_PLOT_CONFIG: 'We couldn\'t get the plot settings.',
  ERROR_FETCHING_PLOT_DATA: 'We couldn\'t get the plot data.',
  ERROR_FETCHING_NORMALIZED_EXPRESSION_MATRIX: 'We couldn\'t download the normalized expression matrix.',
  ERROR_NO_MATCHING_CELLS_NORMALIZED_EXPRESSION_MATRIX: 'No cells match requested filters.',
  ERROR_FETCHING_PROCESSING: 'We couldn\'t get the filter settings.',
  ERROR_FETCHING_DATA_PROCESSING_SETTINGS: 'We couldn\'t get the data processing settings.',
  ERROR_FETCHING_PROJECTS: 'We couldn\'t get the list of projects.',
  ERROR_FETCHING_SAMPLES: 'We couln\'t get your samples.',
  ERROR_FETCHING_STATUS: 'We couldn\'t get the status of pending jobs.',
  ERROR_FETCHING_BACKEND_STATUS: 'We couldn\'t get the status of the experiment\'s data processing.',
  ERROR_FETCH_BACKGROUND_GENE_EXP: 'We couldn\'t get the background gene expression',
  ERROR_FETCH_MARKER_GENES: 'We couldn\'t get the marker genes',
  ERROR_FILE_CATEGORY: 'The selected file name does not match the expected category.',
  ERROR_FILES_FOLDER: 'Only files contained in a folder are accepted.',
  ERROR_METADATA_MULTIPLE_FILES: 'You need to upload a single file in .tsv format',
  ERROR_METADATA_WRONG_FORMAT: 'The file does not seem to be in the correct tsv key-value format.',
  ERROR_CELL_LEVEL_COLUMN: 'The .tsv file needs to contain the column "barcode"',
  ERROR_SEURAT_MULTIPLE_FILES: 'Only a single rds file is accepted for Seurat uploads.',
  ERROR_SEURAT_EXISTING_FILE: 'Please remove existing seurat rds file before uploading a new one.',
  ERROR_SEURAT_MAX_FILE_SIZE: 'rds objects must be less than 15 GB.',
  ERROR_NOT_SIGNED_IN: 'You need to sign in first. Click Sign in on the top right to begin.',
  ERROR_NO_PERMISSIONS: 'You do not have permissions to perform that action.',
  ERROR_LAUNCH_PATHWAY: 'We couldn\'t launch pathway analysis',
  ERROR_SAVING: 'We couldn\'t save your data.',
  ERROR_BEGIN_SAMPLE_FILE_UPLOAD: 'We couldn\'t begin the files upload',
  ERROR_UPDATE_SERVER_ON_UPLOAD_STATE: 'We couldn\'t update the upload state in our servers. Try refreshing the page.',
  ERROR_SAMPLE_TECHNOLOGY: 'Selected technology does not match across all samples. Select the right technology and try again.',
  ERROR_SAVING_PLOT_CONFIG: 'We couldn\'t save your plot configuration.',
  ERROR_STARTING_PIPLELINE: 'We couldn\'t start working on your data.',
  ERROR_SIGN_IN: 'We couldn\'t sign you in. Please try again.',
  ERROR_DOWNLOADING_SEURAT_OBJECT: 'We couldn\'t get the processed Seurat object.',
  ERROR_MULTIPLE_SAMPLELT_FILES: 'More than one Sample Loading Table was selected, only one will be used.',
  ERROR_FAILED_SAMPLELT_FILE: 'Sample Loading Table must be a valid excel .xlsm file',
  ERROR_HIDDEN_FILE: 'Files starting with "." or "MACOSX" are hidden or system.',
  ERROR_NOT_FASTQ: 'File name does not end with ".fastq.gz" or ".fq.gz".',
  ERROR_FASTQ_NOT_GZIPPED: 'File name does not end with ".gz", only gzip compressed files can be uploaded.',
  ERROR_ALREADY_UPLOADED: 'File already uploaded or is being uploaded.',
  ERROR_READ_PAIR_NOT_IN_NAME: 'File name must include "_R1" or "_R2" (its read pair). Valid example: "S1_R1.fast.gz"',
  ERROR_TOO_MANY_READS_IN_NAME: 'File name can\'t contain "_R1" or "_R2" (its read pair) more than once. Valid example: "S1_R1.fast.gz"',
  ERROR_MORE_FILES_THAN_SUBLIBRARIES: 'You have uploaded more pairs of FASTQ files than the specified number of sublibraries. Please check the input number of sublibraries or contact support about concatenating your FASTQ files.',
  ERROR_LESS_FILES_THAN_SUBLIBRARIES: "You have uploaded less pairs of FASTQ files than the specified number of sublibraries. Please check the input number of sublibraries or make sure your FASTQ files haven't been concatenated.",
  FEEDBACK_ERROR: 'We couldn\'t process your feedback. Please try submitting again.',
  FEEDBACK_SUCCESSFUL: 'Your feedback has been successfully sent. Thank you!',
  REFERRAL_ERROR: 'We couldn\'t process your referral. Please try submitting again.',
  REFERRAL_EMAIL_ERROR: 'The error you submitted was invalid. Please check again.',
  REFERRAL_SUCCESSFUL: 'Your invitation has been successfully sent. Thank you!',
  SUCCESS_NEW_CLUSTER_CREATED: __jsx(React.Fragment, null, "Created a new cluster. Check \xA0", __jsx("b", null, "Custom cell sets"), "\xA0 in the \xA0", __jsx("b", null, "Cell sets and Metadata "), "tile."),
  SUCCESS_CELL_SETS_RECLUSTERED: 'Cell sets have been reclustered. Your cell sets have changed.',
  SUCCESS_CELL_SETS_ANNOTATED: 'Cell sets have been annotated. Your cell sets have changed.',
  WORK_REQUEST_TIMED_OUT_RETRY: 'The task is taking longer than expected, please retry.',
  SAVING_SAMPLE: 'Saving sample...',
  SAVING_PROJECT: 'Saving project...',
  SHARE_SUCESS_NOTIFICATION_FAILURE: 'The project was shared, but we weren’t able to notify the new collaborator',
  SHARE_FAILURE: 'We weren\'t able to share the project',
  ERROR_CLONING_DEFAULT: 'We couldn\'t clone the project.',
  ERROR_CLONING_PIPELINE_LOCKED: 'We couldn\'t clone the project because data processing is currently running, wait until it finishes and try again',
  ERROR_LAUNCHING_SECONDARY_ANALYSIS: 'There was a problem launching the pipeline.',
  ERROR_FASTQS_INCORRECTLY_PAIRED: 'Some fastqs are missing their corresponding read pairs.',
  ERROR_CANCELLING_SECONDARY_ANALYSIS: 'There was a problem cancelling your analysis.',
  ERROR_CELL_SETS_ANNOTATION_FAILED: 'ScType annotation unsuccessful. Please ensure dataset contains gene symbols.'
};