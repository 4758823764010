import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _map from "lodash/map";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
var secondaryAnalysesLoaded = produce(function (draft, action) {
  var secondaryAnalyses = action.payload.secondaryAnalyses;
  var originalState = original(draft);
  var newActiveSecondaryAnalysisId = originalState.meta.activeSecondaryAnalysisId;
  // If the current active analysis no longer exists, change it
  if (!Object.keys(originalState).includes(newActiveSecondaryAnalysisId)) {
    var _secondaryAnalyses$;
    newActiveSecondaryAnalysisId = (_secondaryAnalyses$ = secondaryAnalyses[0]) === null || _secondaryAnalyses$ === void 0 ? void 0 : _secondaryAnalyses$.id;
  }
  var ids = _map(secondaryAnalyses, 'id');
  draft.meta.activeSecondaryAnalysisId = newActiveSecondaryAnalysisId;
  draft.meta.loading = false;
  draft.meta.initialLoadPending = false;
  draft.ids = ids;
  secondaryAnalyses.forEach(function (analysis) {
    var _originalState$analys, _originalState$analys2;
    draft[analysis.id] = _objectSpread({
      files: ((_originalState$analys = originalState[analysis.id]) === null || _originalState$analys === void 0 ? void 0 : _originalState$analys.files) || {
        data: {},
        loading: false,
        error: false
      },
      status: ((_originalState$analys2 = originalState[analysis.id]) === null || _originalState$analys2 === void 0 ? void 0 : _originalState$analys2.status) || {
        current: null,
        loading: false,
        error: false
      }
    }, analysis);
  });
});
export default secondaryAnalysesLoaded;