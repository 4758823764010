var _nextConfig;
import nextConfig from 'next/config';
var accountId = (_nextConfig = nextConfig()) === null || _nextConfig === void 0 || (_nextConfig = _nextConfig.publicRuntimeConfig) === null || _nextConfig === void 0 ? void 0 : _nextConfig.accountId;
var accountInfo = {
  default: {
    supportEmail: 'hello@biomage.net'
  }
};
var getAccountInfo = function getAccountInfo(account) {
  return accountInfo[account] || accountInfo.default;
};
var config = {
  supportEmail: getAccountInfo(accountId).supportEmail,
  pipelineVersionToRerunQC: 2,
  workerVersion: 4 // needs to match workerVersion in API
};

export default config;