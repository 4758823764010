import _cloneDeep from "lodash/cloneDeep";
import { HYDRATE } from 'next-redux-wrapper';
import initialState from './initialState';
import { SECONDARY_ANALYSES_CREATED, SECONDARY_ANALYSES_ERROR, SECONDARY_ANALYSES_LOADED, SECONDARY_ANALYSES_LOADING, SECONDARY_ANALYSES_SAVING, SECONDARY_ANALYSES_SET_ACTIVE, SECONDARY_ANALYSES_DELETED, SECONDARY_ANALYSES_UPDATED, SECONDARY_ANALYSIS_FILES_LOADING, SECONDARY_ANALYSIS_FILES_LOADED, SECONDARY_ANALYSIS_FILES_ERROR, SECONDARY_ANALYSIS_FILES_UPDATE, SECONDARY_ANALYSIS_FILES_DELETE, SECONDARY_ANALYSIS_STATUS_LOADING, SECONDARY_ANALYSIS_STATUS_LOADED, SECONDARY_ANALYSIS_LOGS_LOADING, SECONDARY_ANALYSIS_LOGS_LOADED } from '../../actionTypes/secondaryAnalyses';
import secondaryAnalysesLoading from './secondaryAnalysesLoading';
import secondaryAnalysesLoaded from './secondaryAnalysesLoaded';
import secondaryAnalysesSaving from './secondaryAnalysesSaving';
import secondaryAnalysesError from './secondaryAnalysesError';
import secondaryAnalysisCreated from './secondaryAnalysisCreated';
import secondaryAnalysisSetActive from './secondaryAnalysisSetActive';
import secondaryAnalysisDeleted from './secondaryAnalysisDeleted';
import secondaryAnalysisUpdated from './secondaryAnalysisUpdated';
import secondaryAnalysisFilesLoaded from './secondaryAnalysisFilesLoaded';
import secondaryAnalysisFileUpdated from './secondaryAnalysisFileUpdated';
import secondaryAnalysisFileDeleted from './secondaryAnalysisFileDeleted';
import secondaryAnalysisFilesLoading from './secondaryAnalysisFilesLoading';
import secondaryAnalysisFileError from './secondaryAnalysisFileError';
import secondaryAnalysisStatusLoading from './secondaryAnalysisStatusLoading';
import secondaryAnalysisStatusLoaded from './secondaryAnalysisStatusLoaded';
import secondaryAnalysisLogsLoaded from './secondaryAnalysisLogsLoaded';
import secondaryAnalysisLogsLoading from './secondaryAnalysisLogsLoading';
var notificationsReducer = function notificationsReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SECONDARY_ANALYSES_LOADING:
      {
        return secondaryAnalysesLoading(state, action);
      }
    case SECONDARY_ANALYSES_LOADED:
      {
        return secondaryAnalysesLoaded(state, action);
      }
    case SECONDARY_ANALYSES_CREATED:
      {
        return secondaryAnalysisCreated(state, action);
      }
    case SECONDARY_ANALYSES_SAVING:
      {
        return secondaryAnalysesSaving(state, action);
      }
    case SECONDARY_ANALYSES_ERROR:
      {
        return secondaryAnalysesError(state, action);
      }
    case SECONDARY_ANALYSES_SET_ACTIVE:
      {
        return secondaryAnalysisSetActive(state, action);
      }
    case SECONDARY_ANALYSES_DELETED:
      {
        return secondaryAnalysisDeleted(state, action);
      }
    case SECONDARY_ANALYSES_UPDATED:
      {
        return secondaryAnalysisUpdated(state, action);
      }
    case SECONDARY_ANALYSIS_FILES_LOADING:
      {
        return secondaryAnalysisFilesLoading(state, action);
      }
    case SECONDARY_ANALYSIS_FILES_LOADED:
      {
        return secondaryAnalysisFilesLoaded(state, action);
      }
    case SECONDARY_ANALYSIS_FILES_ERROR:
      {
        return secondaryAnalysisFileError(state, action);
      }
    case SECONDARY_ANALYSIS_FILES_UPDATE:
      {
        return secondaryAnalysisFileUpdated(state, action);
      }
    case SECONDARY_ANALYSIS_FILES_DELETE:
      {
        return secondaryAnalysisFileDeleted(state, action);
      }
    case SECONDARY_ANALYSIS_STATUS_LOADING:
      {
        return secondaryAnalysisStatusLoading(state, action);
      }
    case SECONDARY_ANALYSIS_STATUS_LOADED:
      {
        return secondaryAnalysisStatusLoaded(state, action);
      }
    case SECONDARY_ANALYSIS_LOGS_LOADING:
      {
        return secondaryAnalysisLogsLoading(state, action);
      }
    case SECONDARY_ANALYSIS_LOGS_LOADED:
      {
        return secondaryAnalysisLogsLoaded(state, action);
      }
    case HYDRATE:
      {
        var secondaryAnalyses = action.payload.secondaryAnalyses;
        var activeSecondaryAnalysisId = secondaryAnalyses.meta.activeSecondaryAnalysisId;
        if (activeSecondaryAnalysisId) {
          var _newState = _cloneDeep(state);
          _newState.meta.activeSecondaryAnalysisId = activeSecondaryAnalysisId;
          _newState[activeSecondaryAnalysisId] = secondaryAnalyses[activeSecondaryAnalysisId];
          return _newState;
        }
        return state;
      }
    default:
      {
        return state;
      }
  }
};
export default notificationsReducer;