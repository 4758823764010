import _pickBy from "lodash/pickBy";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
var FASTQ = 'fastq';
var getFastqFiles = function getFastqFiles(secondaryAnalysisId) {
  return function (state) {
    var _state$secondaryAnaly;
    return _pickBy((_state$secondaryAnaly = state[secondaryAnalysisId]) === null || _state$secondaryAnaly === void 0 ? void 0 : _state$secondaryAnaly.files.data, function (file) {
      return file.type === FASTQ;
    });
  };
};
export default createMemoizedSelector(getFastqFiles);