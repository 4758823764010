/* eslint-disable no-param-reassign */
var _require = require('immer'),
  produce = _require.default;
var secondaryAnalysisFilesLoaded = produce(function (draft, action) {
  var _action$payload = action.payload,
    files = _action$payload.files,
    secondaryAnalysisId = _action$payload.secondaryAnalysisId;
  draft[secondaryAnalysisId].files.loading = false;
  files.forEach(function (file) {
    draft[secondaryAnalysisId].files.data[file.id] = file;
    draft[secondaryAnalysisId].files.data[file.id].upload.status = {
      current: file.upload.status,
      loading: false
    };
  });
});
export default secondaryAnalysisFilesLoaded;