import _isNil from "lodash/isNil";
var downloadFromUrl = function downloadFromUrl(url) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var _options$fileName = options.fileName,
    fileName = _options$fileName === void 0 ? null : _options$fileName,
    _options$newTab = options.newTab,
    newTab = _options$newTab === void 0 ? false : _options$newTab;
  var link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  if (newTab) link.target = '_blank';
  if (!_isNil(fileName)) link.download = fileName;
  document.body.appendChild(link);
  link.click();
  setTimeout(function () {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
};
export default downloadFromUrl;