var __jsx = React.createElement;
/* eslint-disable react/no-danger */
import React from 'react';
import Head from 'next/head';
import { getTrackingDetails } from 'utils/tracking';
var TagManager = function TagManager(_ref) {
  var environment = _ref.environment,
    cookiesAgreed = _ref.cookiesAgreed;
  var _getTrackingDetails = getTrackingDetails(environment),
    enabled = _getTrackingDetails.enabled,
    containerId = _getTrackingDetails.containerId;
  // if tracking is not enabled don't add tag manager to the head
  if (!enabled || !cookiesAgreed) return null;
  var matomoName = 'biomage';
  var mtmTrackingCode = "var _mtm = window._mtm = window._mtm || [];\n            _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});\n            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n            g.async=true; g.src='https://cdn.matomo.cloud/".concat(matomoName, ".matomo.cloud/container_").concat(containerId, ".js'; s.parentNode.insertBefore(g,s);");
  return __jsx(Head, null, __jsx("script", {
    key: "mtm",
    dangerouslySetInnerHTML: {
      __html: mtmTrackingCode
    }
  }));
};
export default TagManager;