import "antd/lib/result/style";
import _Result from "antd/lib/result";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import React from 'react';
import FeedbackButton from 'components/sider/FeedbackButton';
import signIn from 'utils/signIn';
var Title = _Typography.Title;
var UnauthorizedPage = function UnauthorizedPage(_ref) {
  var title = _ref.title,
    subTitle = _ref.subTitle,
    hint = _ref.hint;
  return __jsx(_Result, {
    title: __jsx(Title, {
      level: 2
    }, title),
    icon: __jsx("img", {
      alt: "People looking into bushes to find something (illustration).",
      src: "/undraw_cancel_u1it.svg",
      width: 250,
      height: 250,
      style: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%'
      }
    }),
    subTitle: __jsx(React.Fragment, null, __jsx(Title, {
      level: 5,
      style: {
        fontWeight: 'normal'
      }
    }, subTitle, hint && __jsx(React.Fragment, null, __jsx("br", null), __jsx("span", null, hint)))),
    extra: __jsx(React.Fragment, null, __jsx(_Button, {
      type: "primary",
      onClick: function onClick() {
        return signIn();
      }
    }, "Log in"), __jsx(FeedbackButton, null))
  });
};
UnauthorizedPage.defaultProps = {
  hint: '',
  title: 'Unauthorized',
  subTitle: 'Login to continue'
};
export default UnauthorizedPage;