var SECONDARY_ANALYSES = 'secondaryAnalyses';
var SECONDARY_ANALYSES_CREATED = "".concat(SECONDARY_ANALYSES, "/created");
var SECONDARY_ANALYSES_LOADING = "".concat(SECONDARY_ANALYSES, "/loading");
var SECONDARY_ANALYSES_LOADED = "".concat(SECONDARY_ANALYSES, "/loaded");
var SECONDARY_ANALYSES_ERROR = "".concat(SECONDARY_ANALYSES, "/error");
var SECONDARY_ANALYSES_CANCELLED = "".concat(SECONDARY_ANALYSES, "/cancelled");
var SECONDARY_ANALYSES_SAVING = "".concat(SECONDARY_ANALYSES, "/saving");
var SECONDARY_ANALYSES_SET_ACTIVE = "".concat(SECONDARY_ANALYSES, "/setActive");
var SECONDARY_ANALYSES_DELETED = "".concat(SECONDARY_ANALYSES, "/deleted");
var SECONDARY_ANALYSES_UPDATED = "".concat(SECONDARY_ANALYSES, "/updated");
var SECONDARY_ANALYSES_LAUNCHED = "".concat(SECONDARY_ANALYSES, "/launched");
var SECONDARY_ANALYSIS_FILES = 'secondaryAnalysisFiles';
var SECONDARY_ANALYSIS_FILES_LOADING = "".concat(SECONDARY_ANALYSIS_FILES, "/loading");
var SECONDARY_ANALYSIS_FILES_LOADED = "".concat(SECONDARY_ANALYSIS_FILES, "/loaded");
var SECONDARY_ANALYSIS_FILES_ERROR = "".concat(SECONDARY_ANALYSIS_FILES, "/error");
var SECONDARY_ANALYSIS_FILES_UPDATE = "".concat(SECONDARY_ANALYSIS_FILES, "/update");
var SECONDARY_ANALYSIS_FILES_CREATED = "".concat(SECONDARY_ANALYSIS_FILES, "/created");
var SECONDARY_ANALYSIS_FILES_DELETE = "".concat(SECONDARY_ANALYSIS_FILES, "/delete");
var SECONDARY_ANALYSIS_STATUS_LOADING = "".concat(SECONDARY_ANALYSES, "/statusLoading");
var SECONDARY_ANALYSIS_STATUS_LOADED = "".concat(SECONDARY_ANALYSES, "/statusLoaded");
var SECONDARY_ANALYSIS_LOGS_LOADING = "".concat(SECONDARY_ANALYSES, "/logsLoading");
var SECONDARY_ANALYSIS_LOGS_LOADED = "".concat(SECONDARY_ANALYSES, "/logsLoaded");
export { SECONDARY_ANALYSES_CREATED, SECONDARY_ANALYSES_LOADING, SECONDARY_ANALYSES_LOADED, SECONDARY_ANALYSES_ERROR, SECONDARY_ANALYSES_SAVING, SECONDARY_ANALYSES_SET_ACTIVE, SECONDARY_ANALYSES_DELETED, SECONDARY_ANALYSES_UPDATED, SECONDARY_ANALYSES_LAUNCHED, SECONDARY_ANALYSIS_FILES_LOADING, SECONDARY_ANALYSIS_FILES_LOADED, SECONDARY_ANALYSIS_FILES_ERROR, SECONDARY_ANALYSIS_FILES_UPDATE, SECONDARY_ANALYSIS_FILES_CREATED, SECONDARY_ANALYSIS_FILES_DELETE, SECONDARY_ANALYSIS_STATUS_LOADING, SECONDARY_ANALYSIS_STATUS_LOADED, SECONDARY_ANALYSES_CANCELLED, SECONDARY_ANALYSIS_LOGS_LOADING, SECONDARY_ANALYSIS_LOGS_LOADED };