import _slicedToArray from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _isEqual from "lodash/isEqual";
import _isNil from "lodash/isNil";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es7.object.entries.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
var getFilterChanges = function getFilterChanges(stepKey) {
  return function (state) {
    var currentStepSettings = state.processing[stepKey];
    var originalStepSettings = state.originalProcessing[stepKey];
    var changedKeys = new Set();

    // The processing config hasn't loaded yet
    if (_isNil(currentStepSettings)) return [];

    // Store the key of every entry that changed
    Object.entries(currentStepSettings).forEach(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        key = _ref2[0],
        value = _ref2[1];
      if (!_isEqual(value, originalStepSettings[key])) {
        changedKeys.add(key);
      }
    });
    return changedKeys;
  };
};
export default createMemoizedSelector(getFilterChanges);