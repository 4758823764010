/* eslint-disable no-param-reassign */
import produce from 'immer';
var secondaryAnalysisLogsLoaded = produce(function (draft, action) {
  var _action$payload = action.payload,
    secondaryAnalysisId = _action$payload.secondaryAnalysisId,
    sublibrary = _action$payload.sublibrary,
    process = _action$payload.process,
    data = _action$payload.data;
  draft[secondaryAnalysisId].status.logs[sublibrary][process].loading = false;
  draft[secondaryAnalysisId].status.logs[sublibrary][process].data = data;
});
export default secondaryAnalysisLogsLoaded;