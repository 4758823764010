import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
/* eslint-disable no-param-reassign */

import produce, { original } from 'immer';
var secondaryAnalysisDeleted = produce(function (draft, action) {
  var secondaryAnalysisIds = action.payload.secondaryAnalysisIds;
  var originalState = original(draft);
  var newIds = originalState.ids.filter(function (id) {
    return !secondaryAnalysisIds.includes(id);
  });
  draft.ids = newIds;
  draft.meta.saving = false;
  secondaryAnalysisIds.forEach(function (id) {
    // eslint-disable-next-line no-param-reassign
    delete draft[id];
  });
});
export default secondaryAnalysisDeleted;