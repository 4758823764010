import "antd/lib/modal/style";
import _Modal from "antd/lib/modal";
var __jsx = React.createElement;
import React from 'react';
import { fastLoad } from 'components/Loader';
var IframeModal = function IframeModal(props) {
  var onClose = props.onClose,
    blobToDisplay = props.blobToDisplay;
  return __jsx(_Modal, {
    title: __jsx(React.Fragment, null),
    open: true,
    width: 1200,
    footer: null,
    onOk: onClose,
    onCancel: onClose,
    bodyStyle: {
      height: '90vh'
    },
    style: {
      top: '2vh'
    }
  }, blobToDisplay ? __jsx("iframe", {
    src: URL.createObjectURL(blobToDisplay),
    title: "My Document",
    style: {
      width: '100%',
      height: '100%'
    }
  }) : fastLoad());
};
export default IframeModal;