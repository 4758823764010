import "antd/lib/result/style";
import _Result from "antd/lib/result";
import "antd/lib/button/style";
import _Button from "antd/lib/button";
import "antd/lib/space/style";
import _Space from "antd/lib/space";
import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import "core-js/modules/es7.array.includes.js";
import React from 'react';
import { useSelector } from 'react-redux';
import FeedbackButton from 'components/sider/FeedbackButton';
import postErrorToSlack from 'utils/postErrorToSlack';
var Title = _Typography.Title,
  Text = _Typography.Text;
var Error = function Error(props) {
  var errorText = props.errorText,
    statusCode = props.statusCode,
    errorObject = props.errorObject;
  var environment = useSelector(function (state) {
    return state.networkResources.environment;
  });
  var reduxState = useSelector(function (state) {
    return state;
  });
  if (errorObject && ['production', 'staging'].includes(environment)) {
    postErrorToSlack(errorObject, reduxState);
  }
  return __jsx(_Result, {
    title: __jsx(Title, {
      level: 2
    }, "It's not you, it's us."),
    icon: __jsx("img", {
      alt: "A creature ripping the cable between a PC and a server (illustration).",
      src: "/undraw_server_down_s4lk.svg",
      width: 250,
      height: 250
    }),
    subTitle: __jsx(React.Fragment, null, __jsx(Title, {
      level: 4,
      style: {
        fontWeight: 'normal'
      }
    }, "Sorry, something went wrong on our end. We're working hard to fix the problem."), __jsx(_Space, {
      direction: "vertical",
      style: {
        width: '100%'
      }
    }, __jsx(Text, null, "If you need immediate help, or if the problem persists, please leave feedback using the button below.", __jsx("br", null), "Thank you for your patience, we'll be up and running shortly."), statusCode && __jsx(Text, {
      type: "secondary"
    }, "HTTP ".concat(statusCode)), errorText && __jsx(React.Fragment, null, __jsx("span", null, __jsx(Text, {
      type: "secondary"
    }, "The error is reported as:\xA0"), __jsx(Text, {
      code: true
    }, errorText))))),
    extra: __jsx("center", null, __jsx(_Space, null, __jsx(FeedbackButton, null), __jsx(_Button, {
      type: "primary",
      onClick: function onClick() {
        return window.location.reload();
      }
    }, "Reload Page")))
  });
};
Error.defaultProps = {
  statusCode: null,
  errorText: null,
  errorObject: null
};
Error.getInitialProps = function (_ref) {
  var res = _ref.res,
    err = _ref.err;
  var statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return {
    statusCode: statusCode
  };
};
export default Error;