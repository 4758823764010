/* eslint-disable no-param-reassign */
import produce from 'immer';
var secondaryAnalysisLogsLoading = produce(function (draft, action) {
  var _action$payload = action.payload,
    secondaryAnalysisId = _action$payload.secondaryAnalysisId,
    sublibrary = _action$payload.sublibrary,
    process = _action$payload.process;
  if (!draft[secondaryAnalysisId].status.logs) {
    draft[secondaryAnalysisId].status.logs = {};
  }
  var logsDraft = draft[secondaryAnalysisId].status.logs;
  if (!logsDraft[sublibrary]) {
    logsDraft[sublibrary] = {};
  }
  logsDraft[sublibrary][process] = {};
  logsDraft[sublibrary][process].loading = true;
});
export default secondaryAnalysisLogsLoading;