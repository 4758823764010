import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.match.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.map.js";
var rules = {
  MIN_1_CHAR: 'MIN_1_CHAR',
  MIN_8_CHARS: 'MIN_8_CHARS',
  MIN_2_SEQUENTIAL_CHARS: 'MIN_2_SEQUENTIAL_CHARS',
  ALPHANUM_SPACE: 'ALPHANUM_SPACE',
  ALPHANUM_DASH_SPACE: 'ALPHANUM_DASH_SPACE',
  UNIQUE_NAME: 'UNIQUE_NAME',
  UNIQUE_NAME_CASE_INSENSITIVE: 'UNIQUE_NAME_CASE_INSENSITIVE',
  START_WITH_ALPHABET: 'START_WITH_ALPHABET',
  VALID_EMAIL: 'VALID_EMAIL',
  VALID_UUID: 'VALID_UUID'
};
var errorMessages = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, rules.MIN_1_CHAR, 'Min 1 alphanumeric characters'), rules.MIN_8_CHARS, 'Min 8 alphanumeric characters'), rules.MIN_2_SEQUENTIAL_CHARS, 'Min. 2 characters in sequence'), rules.ALPHANUM_SPACE, 'Only letters, numbers and space allowed'), rules.ALPHANUM_DASH_SPACE, 'Only letters, numbers, space, _, and - allowed'), rules.UNIQUE_NAME, 'Name is already used'), rules.UNIQUE_NAME_CASE_INSENSITIVE, 'Name is already used'), rules.START_WITH_ALPHABET, 'Name can only start with letter'), rules.VALID_EMAIL, 'Invalid email'), rules.VALID_UUID, 'Invalid UUID');
var validationFns = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, rules.MIN_1_CHAR, function (checkName, input) {
  if (!input.match(/^[a-zA-Z\d]{1,}/gm)) return errorMessages[checkName];
  return true;
}), rules.MIN_8_CHARS, function (checkName, input) {
  if (input.length < 8) return errorMessages[checkName];
  return true;
}), rules.MIN_2_SEQUENTIAL_CHARS, function (checkName, input) {
  if (!input.match(/([a-zA-Z\d]{2,}){1,}/gm)) return errorMessages[checkName];
  return true;
}), rules.ALPHANUM_SPACE, function (checkName, input) {
  if (input.match(/[^a-zA-Z\s\d]/gm)) return errorMessages[checkName];
  return true;
}), rules.ALPHANUM_DASH_SPACE, function (checkName, input) {
  if (input.match(/[^a-zA-Z\s\d-_]/gm)) return errorMessages[checkName];
  return true;
}), rules.UNIQUE_NAME, function (checkName, input, params) {
  if (!(params !== null && params !== void 0 && params.existingNames) || params.existingNames.length === 0) return true;
  var existingNames = params.existingNames;
  if (existingNames instanceof Array && existingNames.includes(input)) return errorMessages[checkName];
  if (existingNames instanceof Set && existingNames.has(input)) return errorMessages[checkName];
  return true;
}), rules.UNIQUE_NAME_CASE_INSENSITIVE, function (checkName, input, params) {
  if (!(params !== null && params !== void 0 && params.existingNames) || params.existingNames.length === 0) return true;
  var existingNames = params.existingNames;
  var existingNamesArr = existingNames instanceof Set ? Array.from(existingNames) : existingNames;
  var lowerCaseNames = existingNamesArr.map(function (name) {
    return name.toLowerCase();
  });
  if (lowerCaseNames.includes(input.toLowerCase())) return errorMessages[checkName];
  return true;
}), rules.START_WITH_ALPHABET, function (checkName, input) {
  if (input.match(/^[^a-zA-Z]/gm)) return errorMessages[checkName];
  return true;
}), rules.VALID_EMAIL, function (checkName, input) {
  // Valid email regex based on RC 5322 - https://emailregex.com/
  var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!input.match(emailRegex)) return errorMessages[checkName];
  return true;
}), rules.VALID_UUID, function (checkName, input) {
  var uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
  if (!input.match(uuidRegex)) return errorMessages[checkName];
  return true;
});

/**
 * @typedef {Object} ValidateInputsReturns
 * @property {boolean} isValid - True if all checks passed, false otherwise
 * @property {results} results - An array of validation results or error messages
 */

/**
 * Valide input according to a list of checks which might be configured by passing params.
 * @param {string} input - The input string to validate.
 * @param {string[]} checks - Functions to check.
 * @param {Object} params - Optional parameters passed to checking functions
 * @returns {ValidateInputsReturns} - An object with isValid and results properties
 */

var validateInput = function validateInput(input, checks, params) {
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(checks)) checks = [checks];
  if (checks.length === 0) {
    return {
      isValid: true,
      results: []
    };
  }
  var results = checks.map(function (checkName) {
    return validationFns[checkName](checkName, input, params);
  });
  var isValid = results.every(function (check) {
    return check === true;
  });
  return {
    isValid: isValid,
    results: results
  };
};
export default validateInput;
export { rules, errorMessages };