import "antd/lib/typography/style";
import _Typography from "antd/lib/typography";
var __jsx = React.createElement;
import { ClipLoader, BounceLoader } from 'react-spinners';
import useSWR from 'swr';
import React from 'react';
import { useSelector } from 'react-redux';
import fetchAPI from 'utils/http/fetchAPI';
var Text = _Typography.Text;
var slowLoad = function slowLoad() {
  return __jsx(React.Fragment, null, __jsx("div", {
    style: {
      padding: 25
    }
  }, __jsx("center", null, __jsx(BounceLoader, {
    size: 50,
    color: "#8f0b10",
    css: {
      display: 'block'
    }
  }))), __jsx("p", null, __jsx(Text, null, "This will take a few minutes...")), __jsx("p", null, __jsx(Text, {
    type: "secondary"
  }, "We're setting up your analysis after a period of inactivity. Please wait.")));
};
var fastLoad = function fastLoad(message) {
  return __jsx("div", {
    style: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }, __jsx("div", {
    style: {
      padding: 25
    }
  }, __jsx(ClipLoader, {
    size: 50,
    color: "#8f0b10"
  })), __jsx("p", {
    style: {
      textAlign: 'center'
    }
  }, __jsx(Text, null, message !== null && message !== void 0 ? message : "We're getting your data ...")));
};
var Loader = function Loader(_ref) {
  var _backendStatus$experi;
  var experimentId = _ref.experimentId;
  var backendStatus = useSelector(function (state) {
    return state.backendStatus;
  });
  var workerInfo = backendStatus === null || backendStatus === void 0 || (_backendStatus$experi = backendStatus[experimentId]) === null || _backendStatus$experi === void 0 || (_backendStatus$experi = _backendStatus$experi.status) === null || _backendStatus$experi === void 0 ? void 0 : _backendStatus$experi.worker;
  var _useSWR = useSWR(function () {
      return experimentId ? "/v2/experiments/".concat(experimentId, "/backendStatus") : null;
    }, fetchAPI),
    workerStatus = _useSWR.data;
  if (!workerStatus) {
    return __jsx("div", null, fastLoad('Assigning a worker to your analysis'));
  }
  if (workerInfo && workerInfo.userMessage) {
    var userMessage = workerInfo.userMessage;
    return __jsx("div", null, fastLoad(userMessage));
  }
  var _workerStatus$worker = workerStatus.worker,
    started = _workerStatus$worker.started,
    ready = _workerStatus$worker.ready;
  if (started && ready) {
    return __jsx("div", null, fastLoad('Assigning a worker to your analysis'));
  }
  return __jsx("div", null, slowLoad());
};
export default Loader;
export { fastLoad, slowLoad };