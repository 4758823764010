import _pickBy from "lodash/pickBy";
import "core-js/modules/es7.object.values.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
var SAMPLE_LOADING_TABLE = 'samplelt';
var getSampleLTFile = function getSampleLTFile(secondaryAnalysisId) {
  return function (state) {
    var _state$secondaryAnaly;
    var files = _pickBy((_state$secondaryAnaly = state[secondaryAnalysisId]) === null || _state$secondaryAnaly === void 0 ? void 0 : _state$secondaryAnaly.files.data, function (file) {
      return file.type === SAMPLE_LOADING_TABLE;
    });

    // Single file to return, so just return it
    return Object.values(files)[0];
  };
};
export default createMemoizedSelector(getSampleLTFile);