import _isNil from "lodash/isNil";
/* eslint-disable no-param-reassign */
import produce, { original } from 'immer';
var secondaryAnalysisFileUpdated = produce(function (draft, action) {
  var _original, _draft$secondaryAnaly;
  var _action$payload = action.payload,
    uploadStatus = _action$payload.uploadStatus,
    fileId = _action$payload.fileId,
    secondaryAnalysisId = _action$payload.secondaryAnalysisId,
    percentProgress = _action$payload.percentProgress,
    abortController = _action$payload.abortController;
  if (_isNil((_original = original((_draft$secondaryAnaly = draft[secondaryAnalysisId]) === null || _draft$secondaryAnaly === void 0 ? void 0 : _draft$secondaryAnaly.files)) === null || _original === void 0 ? void 0 : _original.data[fileId])) return;
  var uploadDraft = draft[secondaryAnalysisId].files.data[fileId].upload;
  uploadDraft.status.current = uploadStatus;
  uploadDraft.percentProgress = percentProgress;
  if (abortController) {
    uploadDraft.abortController = abortController;
  }
});
export default secondaryAnalysisFileUpdated;