var initialState = {
  meta: {
    loading: false,
    error: false,
    saving: false,
    activeSecondaryAnalysisId: null,
    initialLoadPending: true
  },
  ids: []
};
export default initialState;