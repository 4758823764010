import "core-js/modules/es6.object.keys.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.object.get-own-property-descriptor.js";
import "core-js/modules/es7.object.get-own-property-descriptors.js";
import _defineProperty from "/home/runner/work/ui/ui/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { runGem2s, runQC, runSeurat } from 'redux/actions/pipeline';
import calculateGem2sRerunStatus from 'utils/data-management/calculateGem2sRerunStatus';
import calculateQCRerunStatus from 'utils/data-management/calculateQCRerunStatus';
var calculatePipelinesRerunStatus = function calculatePipelinesRerunStatus(gem2sBackendStatus, qcBackendStatus, activeExperiment, isTechSeurat) {
  var gem2sRerunStatus = calculateGem2sRerunStatus(gem2sBackendStatus, activeExperiment);
  var qcRerunStatus = calculateQCRerunStatus(qcBackendStatus, gem2sBackendStatus);
  if (gem2sRerunStatus.rerun) {
    return _objectSpread({
      runPipeline: isTechSeurat ? runSeurat : runGem2s
    }, gem2sRerunStatus);
  }
  if (isTechSeurat) {
    return {
      runPipeline: null,
      rerun: false,
      complete: true,
      reasons: []
    };
  }
  return _objectSpread({
    runPipeline: runQC
  }, qcRerunStatus);
};
export default calculatePipelinesRerunStatus;